import { callAndWaitForEvent, getConnectedAddress } from "@cyberpnk/component-library";
import {
  getPunksWrapperProxyCollectionContract,
  getCryptoPunksMarketContract,
} from "../../app/common";
import * as ethers from "ethers";

declare global {
  interface Window { ethereum: any; }
}

export interface CheckForSaleRequest {
  punkId: string;
}

export interface CheckForSaleResponse {
  isForSale: boolean,
  onlySellTo: string,
  minValue: string,
  offeredToEveryone: boolean,
}

export const checkForSale = async ({ punkId }: CheckForSaleRequest): Promise<CheckForSaleResponse> => {
  const punks = await getCryptoPunksMarketContract();

  const { isForSale, onlySellTo, minValue } = await punks.methods.punksOfferedForSale(punkId).call();
  return {
    isForSale,
    onlySellTo,
    minValue,
    offeredToEveryone: onlySellTo === ethers.constants.AddressZero
  };
}

export interface OfferPunkForSaleRequest {
  punkIndex: string;
  minPrice: string;
}
export const offerPunkForSale = async ({ punkIndex, minPrice }: OfferPunkForSaleRequest) => {
  const minPriceInWei = ethers.utils.parseEther(minPrice);
  await callAndWaitForEvent(getPunksWrapperProxyCollectionContract, "offerPunkForSale", [punkIndex, minPriceInWei], "PunkOffered", { punkIndex, minPrice: minPriceInWei }, getCryptoPunksMarketContract);
}

export interface PunkNoLongerForSaleRequest {
  punkIndex: string;
}
export const punkNoLongerForSale = async ({ punkIndex }: PunkNoLongerForSaleRequest) => {
  await callAndWaitForEvent(getPunksWrapperProxyCollectionContract, "punkNoLongerForSale", [punkIndex], "PunkNoLongerForSale", { punkIndex }, getCryptoPunksMarketContract);
}

export interface UnwrapRequest {
  tokenId: string;
}
export const unwrap = async ({ tokenId }: UnwrapRequest) => {
  const user = await getConnectedAddress();

  await callAndWaitForEvent(getPunksWrapperProxyCollectionContract, "unwrap", [tokenId], "Transfer", { from: user, tokenId, to: ethers.constants.AddressZero });
}

export interface DiscardEmptyWrapperRequest {
  tokenId: string;
}
export const discardEmptyWrapper = async ({ tokenId }: DiscardEmptyWrapperRequest) => {
  await callAndWaitForEvent(getPunksWrapperProxyCollectionContract, "discardEmptyWrapper", [tokenId], "Transfer", { tokenId, to: ethers.constants.AddressZero });
}

