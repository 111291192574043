import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  connectWalletAsync, newWalletConnected,
  SimpleAsyncState, walletDisconnected,
  buildSimpleAsyncCases
} from '@cyberpnk/component-library';
import {
  checkPendingWithdrawals,
  CheckPendingWithdrawalsResponse,
  withdraw
} from './withdrawApi';

export interface WithdrawState {
  checkPendingWithdrawals: SimpleAsyncState<CheckPendingWithdrawalsResponse>;
  withdraw: SimpleAsyncState<void>;
}

const initialState: WithdrawState = {
  checkPendingWithdrawals: { loading: false, },
  withdraw: { loading: false, },
};

export const checkPendingWithdrawalsAsync = createAsyncThunk(
  'withdraw/checkPendingWithdrawals',
  async () => {
    return await checkPendingWithdrawals();
  }
);

export const withdrawAsync = createAsyncThunk(
  'manaagePunkPage/withdraw',
  async () => {
    return await withdraw();
  }
);

const toInitialState = (state: WithdrawState, initialState: WithdrawState) => {
  state.checkPendingWithdrawals = initialState.checkPendingWithdrawals;
  state.withdraw = initialState.withdraw;
}

export const withdrawSlice = createSlice({
  name: 'withdraw',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // this didnt work
    // [checkAsync.pending.type]: (state: WrapPunkPageState, action: { payload: CheckPunksResponse }) => {
    //   state.offerPunkToProxyForWrapping = initialState.offerPunkToProxyForWrapping;
    //   state.isPunkOfferedToProxy = initialState.isPunkOfferedToProxy;
    //   state.wrap = initialState.wrap;
    //   state.createProxy = initialState.createProxy;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    buildSimpleAsyncCases(builder, checkPendingWithdrawalsAsync, "checkPendingWithdrawals");
    buildSimpleAsyncCases(builder, withdrawAsync, "withdraw");

    builder
      .addCase(connectWalletAsync.pending, (state) => {
        toInitialState(state, initialState);
      })
      .addCase(newWalletConnected, (state) => {
        toInitialState(state, initialState);
      })
      .addCase(walletDisconnected, (state) => {
        toInitialState(state, initialState);
      })
  },
});

// export const {
//   // changeSelectedPfpContract,
// } = withdrawSlice.actions;

export const selectWithdrawState = (state: any): WithdrawState => state.withdraw;

export const selectCheckPendingWithdrawals = (state: any) => selectWithdrawState(state).checkPendingWithdrawals;
export const selectWithdraw = (state: any) => selectWithdrawState(state).withdraw;

export const withdrawReducer = withdrawSlice.reducer;

export default withdrawSlice.reducer;
