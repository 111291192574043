import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  connectWalletAsync, newWalletConnected,
  SimpleAsyncState, walletDisconnected,
  buildSimpleAsyncCases
} from '@cyberpnk/component-library';
import {
  check, checkProxy, CheckProxyRequest,
  CheckProxyResponse, CheckPunkRequest,
  CheckPunksResponse, createProxy,
  CreateProxyResponse,
  GetPunksDataImageRequest,
  getPunksDataImageWithLocalStorageCache,
  isPunkOfferedToProxy,
  IsPunkOfferedToProxyRequest,
  IsPunkOfferedToProxyResponse,
  offerPunkToProxyForWrapping,
  OfferPunkToProxyForWrappingRequest,
  rewrapEmptyWrapper,
  RewrapEmptyWrapperRequest,
  rewrapFullWrapper,
  RewrapFullWrapperRequest,
  wrap, WrapRequest
} from './wrapPunkApi';
import { routerNavigated } from '../../app/history';

export interface WrapPunkPageState {
  check: SimpleAsyncState<CheckPunksResponse, CheckPunkRequest>;
  checkProxy: SimpleAsyncState<CheckProxyResponse, CheckProxyRequest>;
  getPunksDataImage: SimpleAsyncState<string, GetPunksDataImageRequest>;
  createProxy: SimpleAsyncState<CreateProxyResponse>;
  offerPunkToProxyForWrapping: SimpleAsyncState<void>;
  isPunkOfferedToProxy: SimpleAsyncState<IsPunkOfferedToProxyResponse, IsPunkOfferedToProxyRequest>;
  wrap: SimpleAsyncState<void>;
  rewrapFullWrapper: SimpleAsyncState<void, RewrapFullWrapperRequest>;
  rewrapEmptyWrapper: SimpleAsyncState<void, RewrapEmptyWrapperRequest>;
}

const initialState: WrapPunkPageState = {
  check: { loading: false, },
  checkProxy: { loading: false, },
  getPunksDataImage: { loading: false, },
  createProxy: { loading: false, },
  offerPunkToProxyForWrapping: { loading: false, },
  isPunkOfferedToProxy: { loading: false, },
  wrap: { loading: false, },
  rewrapFullWrapper: { loading: false, },
  rewrapEmptyWrapper: { loading: false, },
};


export const checkProxyAsync = createAsyncThunk(
  'wrapPunkPage/checkProxy',
  async () => {
    return await checkProxy();
  }
);

export const checkAsync = createAsyncThunk(
  'wrapPunkPage/check',
  async (request: CheckPunkRequest, thunkAPI) => {
    return await check(request);
  }
);

export const getPunksDataImageAsync = createAsyncThunk(
  'wrapPunkPage/getPunksDataImage',
  async ({ punkId }: GetPunksDataImageRequest, thunkAPI) => {
    return await getPunksDataImageWithLocalStorageCache({ punkId });
  }
);

export const createProxyAsync = createAsyncThunk(
  'wrapPunkPage/createProxy',
  async () => {
    return await createProxy();
  }
);

export const offerPunkToProxyForWrappingAsync = createAsyncThunk(
  'wrapPunkPage/offerPunkToProxyForWrapping',
  async (request: OfferPunkToProxyForWrappingRequest, thunkAPI) => {
    return await offerPunkToProxyForWrapping(request);
  }
);

export const isPunkOfferedToProxyAsync = createAsyncThunk(
  'wrapPunkPage/isPunkOfferedToProxy',
  async (request: IsPunkOfferedToProxyRequest, thunkAPI) => {
    return await isPunkOfferedToProxy(request);
  }
);

export const wrapAsync = createAsyncThunk(
  'wrapPunkPage/wrap',
  async (request: WrapRequest, thunkAPI) => {
    return await wrap(request);
  }
);

export const rewrapFullWrapperAsync = createAsyncThunk(
  'wrapPunkPage/rewrapFullWrapper',
  async (request: RewrapFullWrapperRequest, thunkAPI) => {
    return await rewrapFullWrapper(request);
  }
);

export const rewrapEmptyWrapperAsync = createAsyncThunk(
  'wrapPunkPage/rewrapEmptyWrapper',
  async (request: RewrapEmptyWrapperRequest, thunkAPI) => {
    return await rewrapEmptyWrapper(request);
  }
);

const toInitialState = (state: WrapPunkPageState, initialState: WrapPunkPageState) => {
  state.checkProxy = initialState.checkProxy;
  state.check = initialState.check;
  state.getPunksDataImage = initialState.getPunksDataImage;
  state.createProxy = initialState.createProxy;
  state.offerPunkToProxyForWrapping = initialState.offerPunkToProxyForWrapping;
  state.isPunkOfferedToProxy = initialState.isPunkOfferedToProxy;
  state.wrap = initialState.wrap;
  state.rewrapFullWrapper = initialState.rewrapFullWrapper;
  state.rewrapEmptyWrapper = initialState.rewrapEmptyWrapper;
}

export const wrapPunkPageSlice = createSlice({
  name: 'wrapPunkPage',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // this didnt work
    // [checkAsync.pending.type]: (state: WrapPunkPageState, action: { payload: CheckPunksResponse }) => {
    //   state.offerPunkToProxyForWrapping = initialState.offerPunkToProxyForWrapping;
    //   state.isPunkOfferedToProxy = initialState.isPunkOfferedToProxy;
    //   state.wrap = initialState.wrap;
    //   state.createProxy = initialState.createProxy;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    buildSimpleAsyncCases(builder, checkProxyAsync, "checkProxy");
    buildSimpleAsyncCases(builder, checkAsync, "check");
    buildSimpleAsyncCases(builder, getPunksDataImageAsync, "getPunksDataImage");
    buildSimpleAsyncCases(builder, createProxyAsync, "createProxy");
    buildSimpleAsyncCases(builder, offerPunkToProxyForWrappingAsync, "offerPunkToProxyForWrapping");
    buildSimpleAsyncCases(builder, isPunkOfferedToProxyAsync, "isPunkOfferedToProxy");
    buildSimpleAsyncCases(builder, wrapAsync, "wrap");
    buildSimpleAsyncCases(builder, rewrapFullWrapperAsync, "rewrapFullWrapper");
    buildSimpleAsyncCases(builder, rewrapEmptyWrapperAsync, "rewrapEmptyWrapper");

    builder
      .addCase(connectWalletAsync.pending, (state) => {
        toInitialState(state, initialState);
      })
      .addCase(newWalletConnected, (state) => {
        toInitialState(state, initialState);
      })
      .addCase(walletDisconnected, (state) => {
        toInitialState(state, initialState);
      })
      .addMatcher(({ type }) => type === checkAsync.pending.type, (state) => {
        state.offerPunkToProxyForWrapping = initialState.offerPunkToProxyForWrapping;
        state.isPunkOfferedToProxy = initialState.isPunkOfferedToProxy;
        state.wrap = initialState.wrap;
        state.rewrapFullWrapper = initialState.rewrapFullWrapper;
        state.rewrapEmptyWrapper = initialState.rewrapEmptyWrapper;
        state.createProxy = initialState.createProxy;
      })
      .addMatcher(({ type, payload }) => {
        const id = /wrap-punk\/(\d+)\/?$/.exec(payload?.fragment)?.[1];
        return type === routerNavigated.type && id === undefined;
      }, (state) => {
        toInitialState(state, initialState);
      })
  },
});

// export const {
// } = wrapPunkPageSlice.actions;

export const selectWrapPunkPageState = (state: any): WrapPunkPageState => state.wrapPunkPage;

export const selectCheck = (state: any) => selectWrapPunkPageState(state).check;
export const selectCheckProxy = (state: any) => selectWrapPunkPageState(state).checkProxy;
export const selectGetPunksDataImage = (state: any) => selectWrapPunkPageState(state).getPunksDataImage;
export const selectCreateProxy = (state: any) => selectWrapPunkPageState(state).createProxy;
export const selectOfferPunkToProxyForWrapping = (state: any) => selectWrapPunkPageState(state).offerPunkToProxyForWrapping;
export const selectIsPunkOfferedToProxy = (state: any) => selectWrapPunkPageState(state).isPunkOfferedToProxy;
export const selectWrap = (state: any) => selectWrapPunkPageState(state).wrap;
export const selectRewrapFullWrapper = (state: any) => selectWrapPunkPageState(state).rewrapFullWrapper;
export const selectRewrapEmptyWrapper = (state: any) => selectWrapPunkPageState(state).rewrapEmptyWrapper;

export const wrapPunkPageReducer = wrapPunkPageSlice.reducer;

export default wrapPunkPageSlice.reducer;
