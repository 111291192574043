import { abi as CryptoPunksData } from "../contract-data/CryptoPunksData.json";
import { abi as CryptoPunksMarket } from "../contract-data/CryptoPunksMarket.json";
import { abi as PunksWrapperProxyCollection } from "../contract-data/PunksWrapperProxyCollection.json";
import { abi as PunksWrapperProxy } from "../contract-data/PunksWrapperProxy.json";
import { abi as PunksWrapperProxyCollectionRenderer } from "../contract-data/PunksWrapperProxyCollectionRenderer.json";
import { getContract, getContractByAddress } from "@cyberpnk/component-library";
import { AbiItem } from "web3-utils";
import addresses from "../contract-data/contract-addresses.json";

export const getCryptoPunksMarketContract = async () => {
  return getContract("CryptoPunksMarket", addresses, CryptoPunksMarket as AbiItem[]);
}

export const getCryptoPunksDataContract = async () => {
  return getContract("CryptoPunksData", addresses, CryptoPunksData as AbiItem[]);
}

export const getPunksWrapperProxyCollectionContract = async () => {
  return getContract("PunksWrapperProxyCollection", addresses, PunksWrapperProxyCollection as AbiItem[]);
}

export const getPunksWrapperProxyContract = async (address: string) => {
  return getContractByAddress(address, PunksWrapperProxy as AbiItem[]);
}

export const getPunksWrapperProxyCollectionRendererContract = async () => {
  return getContract("PunksWrapperProxyCollectionRenderer", addresses, PunksWrapperProxyCollectionRenderer as AbiItem[]);
}
