import React from 'react';
import styles from './PunkUi.module.css';
import {
  Article,
  EtherscanLink,
  Network,
} from '@cyberpnk/component-library';
import { CheckPunksResponse } from '../WrapPunkPage/wrapPunkApi';

export function PunkUi({ check, getPunksDataImage, network, punkId }: {
  check: CheckPunksResponse;
  getPunksDataImage: string;
  network: Network;
  punkId?: string;
}) {

  return (
    <>
      <Article>
        <p>
          <svg shapeRendering="crispEdges" className={styles.punksvg + ' '+ (check.wrapperExists ? styles.wrapped : '')} xmlns="http://www.w3.org/2000/svg" version="1.2" viewBox="0 0 640 640" dangerouslySetInnerHTML={{ __html: getPunksDataImage.replace("^data:image/svg+xml;utf8,", "") }}></svg>
        </p>
        <ul>
          {check.wrapperExists ? <>
            <li>{check.isEmptyWrapper ? `Punk #${punkId} was wrapped, and now the wrapper is empty` : `Punk #${punkId} is wrapped`}</li>
            <li>{check.wrapperIsOwnedByUser ? `You own punk #${punkId}` : <>Punk #{punkId} is owned by <EtherscanLink network={network} address={check.ownerOfWrapper}>{check.ownerOfWrapper}</EtherscanLink></>}</li>
          </> : <>
            <li>This punk is not wrapped</li>
            <li>{check.punkIsOwnedByUser ? `You own punk #${punkId}` : <>Punk #{punkId} is owned by <EtherscanLink network={network} address={check.ownerOfPunk}>{check.ownerOfPunk}</EtherscanLink></>}</li>
          </>}
          <li>Check Punk #{punkId} in the <a target="_blank" rel="noreferrer" href={`https://cryptopunks.app/cryptopunks/details/${punkId}`}>CryptoPunks App</a></li>
        </ul>
      </Article>
    </>
  );
}
