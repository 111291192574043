import { ofType, StateObservable } from "redux-observable";
import { filter, map, switchMap, distinctUntilChanged } from "rxjs/operators";
//@ts-ignore
import { merge, combineLatest, of } from "rxjs";
import { RootState } from "../../app/store";
import { Action } from "@reduxjs/toolkit";
import {
  checkAsync,
  checkForSaleAsync,
  discardEmptyWrapperAsync,
  getPunksDataImageAsync,
  offerPunkForSaleAsync,
  punkNoLongerForSaleAsync,
  selectCheck,
  selectCheckForSale,
  unwrapAsync,
} from "./managePunkPageSlice";
import { CheckForSaleRequest } from "./managePunkApi";
import { CheckPunkRequest } from "../WrapPunkPage/wrapPunkApi";
import { routerNavigated } from "../../app/history";
import { selectConnected } from "@cyberpnk/component-library";

export const checkPunkAfterUnwrapOrDiscardEpic = (action$: any, state$: StateObservable<RootState>) => merge(
  action$.pipe(ofType(unwrapAsync.fulfilled)),
  action$.pipe(ofType(discardEmptyWrapperAsync.fulfilled)),
).pipe(
  map(() => {
    return ({
      check: selectCheck(state$.value),
    })
  }),
  filter(({ check }) => {
    return check.request !== undefined
  }),
  map(({ check }) => {
    return checkAsync(check.request as CheckPunkRequest) as unknown as Action
  }),
);

export const recheckForSaleEpic = (action$: any, state$: StateObservable<RootState>) => merge(
  action$.pipe(ofType(offerPunkForSaleAsync.fulfilled)),
  action$.pipe(ofType(punkNoLongerForSaleAsync.fulfilled)),
).pipe(
  map(() => {
    return ({
      checkForSale: selectCheckForSale(state$.value),
    })
  }),
  filter(({ checkForSale }) => !!checkForSale.request),
  switchMap(({ checkForSale }) => [
    checkForSaleAsync(checkForSale.request as CheckForSaleRequest) as unknown as Action,
  ]),
);

export const routerNavigatedManagePageEpic = (action$: any, state$: StateObservable<RootState>) => merge(
  action$.pipe(ofType(routerNavigated)),
).pipe(
  map((action: any) => {
    return /manage-punk\/(\d+)\/?$/.exec(action.payload.fragment)?.[1];
  }),
  filter((id) => {
    return id !== undefined;
  }),
  switchMap((id) => {
    return combineLatest([
      of(id),
      (state$ as any).pipe(
        map(selectConnected),
        distinctUntilChanged(),
        filter(connected => {
          return connected as boolean
        })
      )
    ])
  }),
  switchMap(([id]) => {
    return [
      checkAsync({ punkId: id }) as unknown as Action,
      checkForSaleAsync({ punkId: id }) as unknown as Action,
      getPunksDataImageAsync({ punkId: id }) as unknown as Action,
    ]
  }),
);

