import React from 'react';
import { useAppSelector } from '../../app/hooks';
import { Article, EtherscanLink, Network, selectNetwork } from '@cyberpnk/component-library';
import addresses from "../../contract-data/contract-addresses.json";

export function AboutPage() {
  const network = useAppSelector(selectNetwork) as Network || Network.Mainnet;

  return (
    <>
      <Article>
        <h1>What is this?</h1>
        <p>A wrapper contract that lets you offer for sale your punk in the native <a target="_blank" rel="noreferrer" href="https://cryptopunks.app">CryptoPunks app</a> and at the same time in OpenSea, LooksRare, and any other ERC721-compatible NFT marketplace.</p>
        <p>Which means you can access the liquidity of all the markets.</p>
      </Article>
      <Article>
        <h1>How does it work?</h1>
        <p>To wrap your punks, head over to the <a href="#/wrap-punk">"Wrap Punk" section</a>.</p>
        <p>If this is the first time you wrap a punk with us, you will need to make an additional transaction.  This will create a new proxy wallet to hold your wrapped punks on your behalf.</p>
        <p>You can manage your wrapped punks held in your proxy wallet in the <a href="#/my-punks">"My Wrapped Punks" section</a>.</p>
        <p>There you will be able to offer your punk and accept bids on them like you would in the <a target="_blank" rel="noreferrer" href="https://cryptopunks.app">CryptoPunks app</a>.</p>
      </Article>
      <Article>
        <h1>Is it safe?</h1>
        <p>You can always unwrap your punks and get the original back to your wallet.</p>
        <p>Nobody except the owner can manage the punks held in the proxy.</p>
        <p>All the proceeds from selling punks through the proxy can be claimed by the owner.  That is, you get the money for your punks.</p>
        <p>Check out the contracts for yourself here: <EtherscanLink network={network} address={addresses[network].PunksWrapperProxy}>PunksWrapperProxy</EtherscanLink> <EtherscanLink network={network} address={addresses[network].PunksWrapperProxyCollection}>PunksWrapperProxyCollection</EtherscanLink>.</p>
      </Article>
      <Article>
        <h1>What are those "Empty Wrappers"?</h1>
        <p>When a wrapped punk sells in the <a href="https://cryptopunks.app">CryptoPunks app</a> the wrapper token becomes an Empty Wrapper.</p>
        <p>This is normal and expected.  The Empty Wrapper cannot be transferred or sold, and cannot be used to manage the unwrapped punk.</p>
        <p>An Empty Wrapper can be safely discarded, and the underlying punk can be wrapped again by the new owner.</p>
      </Article>
    </>
  );
}
