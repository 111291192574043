import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import {
  connectWalletReducer,
  initWallet,
  initWalletEpic,
} from '@cyberpnk/component-library';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { routerNavigated } from './history';
import { createBrowserHistory } from 'history';
import { wrapPunkPageReducer } from '../features/WrapPunkPage/wrapPunkPageSlice';
import {
  checkProxyAndOfferedAfterCreateProxyEpic,
  checkPunkAfterWrapEpic,
  recheckProxyEpic,
  routerNavigatedWrapPageEpic
} from '../features/WrapPunkPage/wrapPunkPageEpic';
import { managePunkPageReducer } from '../features/ManagePunkPage/managePunkPageSlice';
import {
  checkPunkAfterUnwrapOrDiscardEpic,
  recheckForSaleEpic,
  routerNavigatedManagePageEpic
} from '../features/ManagePunkPage/managePunkPageEpic';
import {
  initCheckWithdrawalsEpic,
  recheckWithdrawalsAfterWithdrawEpic
} from '../features/Withdraw/withdrawEpic';
import { withdrawReducer } from '../features/Withdraw/withdrawSlice';

const epicMiddleware = createEpicMiddleware();

export const store = configureStore({
  reducer: {
    connectWallet: connectWalletReducer,
    wrapPunkPage: wrapPunkPageReducer,
    managePunkPage: managePunkPageReducer,
    withdraw: withdrawReducer,
  },
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware();
    return middleware.concat(epicMiddleware);
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const rootEpic = combineEpics(
  initWalletEpic,
  checkPunkAfterWrapEpic,
  recheckProxyEpic,
  checkProxyAndOfferedAfterCreateProxyEpic,
  recheckForSaleEpic,
  checkPunkAfterUnwrapOrDiscardEpic,
  recheckWithdrawalsAfterWithdrawEpic,
  initCheckWithdrawalsEpic,
  routerNavigatedWrapPageEpic,
  routerNavigatedManagePageEpic,
);

// @ts-ignore
epicMiddleware.run(rootEpic);

store.dispatch(initWallet());
// store.dispatch(initBackgroundPage());

const myHistory = createBrowserHistory()

// // Get the current location.
const location = myHistory.location;


// Listen for changes to the current location.
// returns "unlisten"
myHistory.listen(({ location }) => {
  store.dispatch(routerNavigated({
    path: location.pathname,
    fragment: location.hash
  }));
});

store.dispatch(routerNavigated({
  path: location.pathname,
  fragment: location.hash
}));

export const history = myHistory;