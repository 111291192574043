import React from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import styles from './WrapPunkPage.module.css';
import {
  Article, Button, ConnectWallet,
  LoadingOrErrorOrContent, Network,
  selectConnected, selectNetwork,
  LoadingOrErrorOrNothing,
  ButtonWithTokenIdInput,
  isUnknownNetwork,
} from '@cyberpnk/component-library';
import {
  checkAsync,
  checkProxyAsync,
  createProxyAsync,
  getPunksDataImageAsync,
  offerPunkToProxyForWrappingAsync,
  rewrapEmptyWrapperAsync,
  rewrapFullWrapperAsync,
  selectCheck,
  selectCheckProxy,
  selectCreateProxy,
  selectGetPunksDataImage,
  selectIsPunkOfferedToProxy,
  selectOfferPunkToProxyForWrapping,
  selectRewrapEmptyWrapper,
  selectRewrapFullWrapper,
  selectWrap, wrapAsync
} from './wrapPunkPageSlice';
import { PunkUi } from '../Punk/PunkUi';
import { useNavigate, useParams } from 'react-router-dom';

export function WrapPunkPage(props: any) {
  const dispatch = useAppDispatch();
  const connected = useAppSelector(selectConnected);
  const network: Network = useAppSelector(selectNetwork);

  const params = useParams();
  const navigate = useNavigate();

  const isKnownNetwork = !isUnknownNetwork(network);

  const properlyConnected = connected && isKnownNetwork;
  const showConnectStep = !properlyConnected;
  const showMain = properlyConnected;

  const check = useAppSelector(selectCheck);
  const checkProxy = useAppSelector(selectCheckProxy);
  const getPunksDataImage = useAppSelector(selectGetPunksDataImage);
  const offerPunkToProxyForWrapping = useAppSelector(selectOfferPunkToProxyForWrapping);
  const isPunkOfferedToProxy = useAppSelector(selectIsPunkOfferedToProxy);
  const wrap = useAppSelector(selectWrap);
  const rewrapFullWrapper = useAppSelector(selectRewrapFullWrapper);
  const rewrapEmptyWrapper = useAppSelector(selectRewrapEmptyWrapper);
  const createProxy = useAppSelector(selectCreateProxy);

  const disabledButton = wrap.loading || check.loading;

  const punkChecksLoading = check.loading || checkProxy.loading || getPunksDataImage.loading;
  const punkChecksError = check.error || checkProxy.error || getPunksDataImage.error;

  const alreadyProperlyWrapped = check.response?.wrapperExists && !check.response.isEmptyWrapper && check.response.punkWasWrappedByOwner;

  const canWrap = !check.response?.wrapperExists && check.response?.punkIsOwnedByUser;
  const canRewrapFullWrapper = check.response?.wrapperExists && !check.response?.isEmptyWrapper && check.response.wrapperIsOwnedByUser && !check.response.punkWasWrappedByOwner;
  const canRewrapEmptyWrapper = check.response?.wrapperExists && check.response?.isEmptyWrapper && check.response.punkIsOwnedByUser;
  const canInitiateWrapOrRewrap = canWrap || canRewrapFullWrapper || canRewrapEmptyWrapper;

  const needsToOfferPunkToProxy = (canWrap || canRewrapEmptyWrapper) && !isPunkOfferedToProxy.response?.isPunkOfferedToProxy;

  return (
    <>
      {showConnectStep ?
        <ConnectWallet useAppDispatch={useAppDispatch} useAppSelector={useAppSelector} />
        : null}

      {showMain ? <>
        <h2>Enter the Punk ID to wrap</h2>
        <p><ButtonWithTokenIdInput cta={true} disabled={disabledButton} onClick={(tokenId) => {
          navigate(`/wrap-punk/${tokenId}`);
          dispatch(checkAsync({ punkId: tokenId }));
          dispatch(checkProxyAsync());
          dispatch(getPunksDataImageAsync({ punkId: tokenId }));
        }}
          placeholder="e.g. 2121"
          value={params.id}
        >Load Punk</ButtonWithTokenIdInput></p>

        <LoadingOrErrorOrContent loading={punkChecksLoading} error={punkChecksError}>
          {check.response && checkProxy.response && getPunksDataImage.response ? <>
            <div className={styles.layout}>
              <div className={styles.pic}>
                <PunkUi check={check.response} getPunksDataImage={getPunksDataImage.response} network={network} punkId={check.request?.punkId} />
              </div>
              <div className={styles.selects}>
                <Article>
                  {alreadyProperlyWrapped ? <>
                    <p>This punk is wrapped</p>
                    {check.response?.wrapperIsOwnedByUser ? <>
                      <p>You own this punk, do you want to <a href={`#/manage-punk/${check.request?.punkId}`}>manage it</a>?</p>
                    </> : null}
                  </> : <>
                    {!canInitiateWrapOrRewrap ? <p>
                      This punk is not yours, you cannot wrap it.
                    </p> : <>
                      {!checkProxy.response?.hasProxy ? <>
                        <p>The first time you wrap a punk you need to send a transaction to create your own proxy contract to hold your wrapped punks.</p>
                        <Button disabled={createProxy.loading} onClick={() => {
                          dispatch(createProxyAsync());
                        }}>Create Proxy Contract</Button>
                        <LoadingOrErrorOrNothing loading={createProxy.loading} error={createProxy.error} loadingText="Creating proxy contract" />
                      </> : <>
                        {needsToOfferPunkToProxy ? <>
                          <p>The first step of wrapping your punk is to offer it for sale to the proxy contract you created earlier.</p>
                          <p>Nobody else will be able to buy it, and you can undo this removing it from sale, if you want to.</p>
                          <Button disabled={offerPunkToProxyForWrapping.loading} onClick={() => {
                            check.request?.punkId && dispatch(offerPunkToProxyForWrappingAsync({ punkIndex: check.request.punkId }));
                          }}>Offer Punk To Proxy Contract</Button>
                          <LoadingOrErrorOrNothing loading={offerPunkToProxyForWrapping.loading} error={offerPunkToProxyForWrapping.error} loadingText="Offering punk to proxy contract" />
                        </> : <>
                          <p>Now you can wrap your punk</p>
                          <Button disabled={wrap.loading || rewrapFullWrapper.loading || rewrapEmptyWrapper.loading} onClick={() => {
                            if (check.request?.punkId) {
                              if (canWrap) {
                                dispatch(wrapAsync({ tokenId: check.request.punkId }));
                              } else if (canRewrapFullWrapper) {
                                dispatch(rewrapFullWrapperAsync({ tokenId: check.request.punkId }));
                              } else if (canRewrapEmptyWrapper) {
                                dispatch(rewrapEmptyWrapperAsync({ tokenId: check.request.punkId }));
                              }
                            }
                          }}>Wrap Punk</Button>
                          <LoadingOrErrorOrNothing loading={wrap.loading} error={wrap.error} loadingText="Wrapping punk" />
                        </>}

                      </>}
                    </>}
                  </>}
                </Article>
              </div>
            </div>

          </> : null}
        </LoadingOrErrorOrContent>
      </>
        : null
      }
    </>

  );
}
