import { ofType, StateObservable } from "redux-observable";
import { filter, map, switchMap, distinctUntilChanged } from "rxjs/operators";
//@ts-ignore
import { merge, combineLatest, of } from "rxjs";
import { RootState } from "../../app/store";
import { Action, AnyAction } from "@reduxjs/toolkit";
import {
  checkAsync, checkProxyAsync,
  createProxyAsync,
  getPunksDataImageAsync,
  isPunkOfferedToProxyAsync,
  offerPunkToProxyForWrappingAsync,
  rewrapEmptyWrapperAsync,
  rewrapFullWrapperAsync,
  selectCheck,
  selectCheckProxy,
  wrapAsync
} from "./wrapPunkPageSlice";
import { CheckPunkRequest } from "./wrapPunkApi";
import { routerNavigated } from "../../app/history";
import { selectConnected } from "@cyberpnk/component-library";


export const checkPunkAfterWrapEpic = (action$: any, state$: StateObservable<RootState>) => merge(
  action$.pipe(ofType(wrapAsync.fulfilled)),
  action$.pipe(ofType(rewrapFullWrapperAsync.fulfilled)),
  action$.pipe(ofType(rewrapEmptyWrapperAsync.fulfilled)),
).pipe(
  map(() => {
    return ({
      check: selectCheck(state$.value),
    })
  }),
  filter(({ check }) => !!check.request),
  map(({ check }) => checkAsync(check.request as CheckPunkRequest) as unknown as Action),
);

export const recheckProxyEpic = (action$: any, state$: StateObservable<RootState>) => merge(
  action$.pipe(ofType(createProxyAsync.fulfilled)),
).pipe(
  switchMap(() => [
    checkProxyAsync() as unknown as Action,
  ]),
);

export const checkProxyAndOfferedAfterCreateProxyEpic = (action$: any, state$: StateObservable<RootState>) => merge(
  action$.pipe(ofType(offerPunkToProxyForWrappingAsync.fulfilled)),
  action$.pipe(ofType(checkProxyAsync.fulfilled), filter((action) => {
    return (action as AnyAction).payload.hasProxy
  })),
).pipe(
  map(() => {
    return ({
      check: selectCheck(state$.value),
      checkProxy: selectCheckProxy(state$.value),
    })
  }),
  filter(({ check, checkProxy }) => !!check.request && !!checkProxy.response),
  switchMap(({ check, checkProxy }) => [
    isPunkOfferedToProxyAsync({
      proxyAddress: checkProxy.response?.proxy as string,
      punkId: check.request?.punkId as string
    }) as unknown as Action
  ]),
);

export const routerNavigatedWrapPageEpic = (action$: any, state$: StateObservable<RootState>) => merge(
  action$.pipe(ofType(routerNavigated)),
).pipe(
  map((action: any) => {
    return /wrap-punk\/(\d+)\/?$/.exec(action.payload.fragment)?.[1];
  }),
  filter((id) => {
    return id !== undefined;
  }),
  switchMap((id) => {
    return combineLatest([
      of(id),
      (state$ as any).pipe(
        map(selectConnected),
        distinctUntilChanged(),
        filter(connected => {
          return connected as boolean
        })
      )
    ])
  }),
  switchMap(([id]) => {
    return [
      checkAsync({ punkId: id }) as unknown as Action,
      checkProxyAsync() as unknown as Action,
      getPunksDataImageAsync({ punkId: id }) as unknown as Action,
    ]
  }),
);
