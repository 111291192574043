import { callAndWaitForEvent, getConnectedAddress } from "@cyberpnk/component-library";
import {
  getPunksWrapperProxyCollectionContract,
  getCryptoPunksMarketContract,
} from "../../app/common";

declare global {
  interface Window { ethereum: any; }
}

export interface CheckPendingWithdrawalsResponse {
  hasPendingWithdrawal: boolean;
  withdrawalAmount: string;
}

export const checkPendingWithdrawals = async (): Promise<CheckPendingWithdrawalsResponse> => {
  const user = await getConnectedAddress();
  const punks = await getCryptoPunksMarketContract();
  const proxyWrapperPunks = await getPunksWrapperProxyCollectionContract();
  const proxyAddress = await proxyWrapperPunks.methods.proxyForPunksOwner(user).call();

  const withdrawalAmount = await punks.methods.pendingWithdrawals(proxyAddress).call();

  return {
    withdrawalAmount,
    hasPendingWithdrawal: withdrawalAmount !== 0 && withdrawalAmount !== "0",
  };
}

export const withdraw = async () => {
  const user = await getConnectedAddress();

  await callAndWaitForEvent(getPunksWrapperProxyCollectionContract, "withdraw", [], "Withdrawal", { sender: user });
}
