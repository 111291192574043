import React from 'react';
import styles from './App.module.css';
import { useAppSelector, useAppDispatch } from './app/hooks';
import {
  Network, Footer,
  selectNetwork,
  TopBar,
  ConnectWalletStatus,
  Button,
  selectConnected,
  LoadingOrErrorOrNothing
} from '@cyberpnk/component-library';
import { version } from '../package.json';
import { Routes, Route } from "react-router-dom";
import { AboutPage } from './features/AboutPage/AboutPage';
import { WrapPunkPage } from './features/WrapPunkPage/WrapPunkPage';
import addresses from "./contract-data/contract-addresses.json";
import { ManagePunkPage } from './features/ManagePunkPage/ManagePunkPage';
import {
  selectCheckPendingWithdrawals,
  selectWithdraw, withdrawAsync
} from './features/Withdraw/withdrawSlice';
import { ethers } from 'ethers';

function App() {
  const dispatch = useAppDispatch();
  const connected = useAppSelector(selectConnected);
  const network: Network = useAppSelector(selectNetwork);
  const checkPendingWithdrawals = useAppSelector(selectCheckPendingWithdrawals);
  const withdraw = useAppSelector(selectWithdraw);
  const showWithdrawalButton = connected && checkPendingWithdrawals.response?.hasPendingWithdrawal;

  return (
    <>
      <div className={styles.App}>
        <TopBar>
          <a href="#/about">What is this?</a>&nbsp;&nbsp;&nbsp;<a href="#/wrap-punk">Wrap Punk</a>&nbsp;&nbsp;&nbsp;<a href="#/manage-punk">Manage Wrapped Punks</a>&nbsp;&nbsp;&nbsp;
          <ConnectWalletStatus useAppDispatch={useAppDispatch} useAppSelector={useAppSelector} />
        </TopBar>
        <main>
          <h2>Proxying Wrapped Punks</h2>
          {showWithdrawalButton ? <>
            <p>
              You can withdraw Eth <Button onClick={() => {
                dispatch(withdrawAsync());
              }} disabled={withdraw.loading}>Withdraw {checkPendingWithdrawals.response?.withdrawalAmount && ethers.utils.formatEther(checkPendingWithdrawals.response?.withdrawalAmount)} ETH</Button>
            </p>
            <LoadingOrErrorOrNothing loading={withdraw.loading} error={withdraw.error} loadingText="Withdrawing Eth" />
          </> : null}

          <div className={styles.main}>
            <Routes>
              <Route path="/" element={<WrapPunkPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/wrap-punk" element={<WrapPunkPage />} />
              <Route path="/manage-punk" element={<ManagePunkPage />} />
              <Route path="/wrap-punk/:id" element={<WrapPunkPage />} />
              <Route path="/manage-punk/:id" element={<ManagePunkPage />} />
            </Routes>

          </div>
        </main>
        <div className={styles.footer}>
          <Footer version={version} network={network} contractAddress={addresses[network]?.PunksWrapperProxyCollection} />
        </div>
      </div>
    </>
  );
}

export default App;
