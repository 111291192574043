import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import styles from './ManagePunkPage.module.css';
import {
  Article, Button, ConnectWallet,
  LoadingOrErrorOrContent, Network,
  selectConnected, selectNetwork,
  ButtonWithInput,
  LoadingOrErrorOrNothing,
  ButtonWithTokenIdInput,
  isUnknownNetwork,
} from '@cyberpnk/component-library';
import {
  checkAsync,
  checkForSaleAsync,
  discardEmptyWrapperAsync,
  getPunksDataImageAsync,
  offerPunkForSaleAsync,
  punkNoLongerForSaleAsync,
  selectCheck,
  selectCheckForSale,
  selectDiscardEmptyWrapper,
  selectGetPunksDataImage,
  selectOfferPunkForSale,
  selectPunkNoLongerForSale,
  selectUnwrap,
  unwrapAsync,
} from './managePunkPageSlice';
import { PunkUi } from '../Punk/PunkUi';
import { useNavigate, useParams } from 'react-router-dom';

export function ManagePunkPage({ match }: any) {
  const dispatch = useAppDispatch();
  const connected = useAppSelector(selectConnected);
  const network: Network = useAppSelector(selectNetwork);
  const params = useParams();
  const navigate = useNavigate();

  const isKnownNetwork = !isUnknownNetwork(network);

  const properlyConnected = connected && isKnownNetwork;
  const showConnectStep = !properlyConnected;
  const showMain = properlyConnected;

  const check = useAppSelector(selectCheck);
  const getPunksDataImage = useAppSelector(selectGetPunksDataImage);
  const unwrap = useAppSelector(selectUnwrap);
  const checkForSale = useAppSelector(selectCheckForSale);
  const offerPunkForSale = useAppSelector(selectOfferPunkForSale);
  const punkNoLongerForSale = useAppSelector(selectPunkNoLongerForSale);
  const discardEmptyWrapper = useAppSelector(selectDiscardEmptyWrapper);

  const disabledButton = unwrap.loading || check.loading;

  const punkChecksLoading = check.loading || checkForSale.loading || getPunksDataImage.loading;
  const punkChecksError = check.error || checkForSale.error || getPunksDataImage.error;


  const [inputValueOfferForSale, setInputValueOfferForSale] = useState("");
  const noValueOfferForSale = inputValueOfferForSale === undefined || inputValueOfferForSale === "";
  const lessThanMinOfferForSale = !noValueOfferForSale && inputValueOfferForSale !== undefined && +inputValueOfferForSale < 0;
  const noNumberInputOfferForSale = isNaN(+inputValueOfferForSale);
  const invalidInputOfferForSale = noNumberInputOfferForSale || lessThanMinOfferForSale;
  const disabledInputOfferForSale = offerPunkForSale.loading || check.loading;
  const disabledButtonOfferForSale = invalidInputOfferForSale || offerPunkForSale.loading || check.loading;

  return (
    <>
      {showConnectStep ?
        <ConnectWallet useAppDispatch={useAppDispatch} useAppSelector={useAppSelector} />
        : null}

      {showMain ? <>
        <h2>Enter the Wrapped Punk ID to Manage</h2>
        <p><ButtonWithTokenIdInput cta={true} disabled={disabledButton} onClick={(punkId) => {
          navigate(`/manage-punk/${punkId}`);
          dispatch(checkAsync({ punkId }));
          dispatch(checkForSaleAsync({ punkId }));
          dispatch(getPunksDataImageAsync({ punkId }));
        }}
          placeholder="e.g. 2121"
          value={params.id}
        >Load Punk</ButtonWithTokenIdInput></p>

        <LoadingOrErrorOrContent loading={punkChecksLoading} error={punkChecksError}>
          {check.response && checkForSale.response && getPunksDataImage.response ? <>
            <div className={styles.layout}>
              <div className={styles.pic}>
                <PunkUi check={check.response} getPunksDataImage={getPunksDataImage.response} network={network} punkId={check.request?.punkId} />
              </div>
              <div className={styles.selects}>
                {!check.response?.wrapperExists ? <>
                  <Article>
                    <p>This punk is not wrapped.</p>
                    {check.response.punkIsOwnedByUser ?
                      <p>Do you want to <a href={`#/wrap-punk/${check.request?.punkId}`}>wrap it</a>?</p>
                      : null}
                  </Article>
                </> : <>
                  {!check.response?.wrapperIsOwnedByUser ?
                    <Article>
                      <p>
                        This punk is not yours, you cannot manage it.
                      </p>
                    </Article>
                    : <>
                      <Article>
                        <h2>Unwrap</h2>
                        <p>Get it back in your wallet.  You can always wrap it again.</p>
                        {check.response.isEmptyWrapper ?
                          <p>This will discard the empty wrapper token.</p>
                          : null}
                        <Button disabled={unwrap.loading || discardEmptyWrapper.loading} onClick={() => {
                          if (check.request?.punkId) {
                            if (check.response?.isEmptyWrapper) {
                              dispatch(discardEmptyWrapperAsync({ tokenId: check.request?.punkId }));
                            } else {
                              dispatch(unwrapAsync({ tokenId: check.request?.punkId }));
                            }
                          }
                        }}>Unwrap punk</Button>
                        <LoadingOrErrorOrNothing loading={unwrap.loading || discardEmptyWrapper.loading} error={unwrap.error || discardEmptyWrapper.error} loadingText="Unwrapping punk" />
                      </Article>
                      {!check.response.punkWasWrappedByOwner ? <>
                        <Article>
                          <h2>Punk wrapped by someone else</h2>
                          <p>You need to <a href={`#/wrap-punk/${check.request?.punkId}`}>rewrap the punk</a> in order to use the proxy functions for this punk.</p>
                        </Article>
                      </> : <>
                        <Article>
                          <h2>Offer for sale</h2>
                          <p>Offer punk for sale in the native CryptoPunksMarket</p>
                          <p>
                            Price in eth <ButtonWithInput
                              disabled={disabledButtonOfferForSale}
                              onClick={() => {
                                check.request?.punkId && dispatch(offerPunkForSaleAsync({
                                  punkIndex: check.request?.punkId,
                                  minPrice: inputValueOfferForSale
                                }));
                              }} input={{
                                onChange: (value) => {
                                  setInputValueOfferForSale(value || "");
                                },
                                disabled: disabledInputOfferForSale,
                                error: invalidInputOfferForSale,
                                placeholder: "e.g. 69.42",
                                value: `${inputValueOfferForSale}`
                              }}>Offer For Sale</ButtonWithInput>
                          </p>
                          <LoadingOrErrorOrNothing loading={offerPunkForSale.loading} error={offerPunkForSale.error} loadingText="Offering for sale" />
                        </Article>

                        {checkForSale.response?.isForSale ? <>
                          <Article>
                            <h2>Remove from sale</h2>
                            <p>Remove it from sale in the native CryptoPunksMarket</p>
                            <Button disabled={punkNoLongerForSale.loading} onClick={() => {
                              check.request?.punkId && dispatch(punkNoLongerForSaleAsync({ punkIndex: check.request?.punkId }));
                            }}>Remove From Sale</Button>
                            <LoadingOrErrorOrNothing loading={punkNoLongerForSale.loading} error={punkNoLongerForSale.error} loadingText="Removing from sale" />
                          </Article>
                        </> : null}
                      </>}
                    </>}
                </>}
              </div>
            </div>

          </> : null}
        </LoadingOrErrorOrContent>
      </>
        : null}
    </>

  );
}
