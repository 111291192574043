import { ofType, StateObservable } from "redux-observable";
import { map, switchMap } from "rxjs/operators";
//@ts-ignore
import { merge } from "rxjs";
import { RootState } from "../../app/store";
import { Action } from "@reduxjs/toolkit";
import {
  checkPendingWithdrawalsAsync,
  withdrawAsync
} from "./withdrawSlice";
import { initWallet } from "@cyberpnk/component-library";

export const recheckWithdrawalsAfterWithdrawEpic = (action$: any, state$: StateObservable<RootState>) => merge(
  action$.pipe(ofType(withdrawAsync.fulfilled)),
).pipe(
  switchMap(() => [
    checkPendingWithdrawalsAsync() as unknown as Action,
  ]),
);


export const initCheckWithdrawalsEpic = (action$: any, state$: StateObservable<RootState>) => action$.pipe(
  ofType(initWallet),
  map((tokenId: string) => checkPendingWithdrawalsAsync()),
);
