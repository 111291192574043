import { callAndWaitForEvent, getConnectedAddress } from "@cyberpnk/component-library";
import { ethers } from "ethers";
import {
  getPunksWrapperProxyCollectionContract,
  getCryptoPunksMarketContract,
  getCryptoPunksDataContract,
} from "../../app/common";

declare global {
  interface Window { ethereum: any; }
}

export interface CheckPunkRequest {
  punkId: string,
}

export interface CheckPunksResponse {
  wrapperExists: boolean;
  ownerOfPunk: string;
  ownerOfWrapper: string;
  isEmptyWrapper: boolean;
  punksOwnerForProxy: string;
  wrapperIsOwnedByUser: boolean;
  punkIsOwnedByUser: boolean;
  punkWasWrappedByOwner: boolean;
}


export interface CheckProxyRequest {
  address: string,
}

export interface CheckProxyResponse {
  hasProxy: boolean;
  proxy: string;
}

const doesntExist = (error: Error) => {
  if (/nonexistent token/.test(error.message)) {
    return "";
  }
  throw error;
};

export const checkProxy = async (): Promise<CheckProxyResponse> => {
  const address = await getConnectedAddress();
  const proxyWrapperPunks = await getPunksWrapperProxyCollectionContract();

  const proxy = await proxyWrapperPunks.methods.proxyForPunksOwner(address).call();
  return {
    proxy,
    hasProxy: proxy !== ethers.constants.AddressZero,
  };
}

export const check = async ({ punkId }: CheckPunkRequest): Promise<CheckPunksResponse> => {
  const expectedOwner = await getConnectedAddress();
  const proxyWrapperPunks = await getPunksWrapperProxyCollectionContract();
  const punks = await getCryptoPunksMarketContract();

  const ownerOfPunk = await punks.methods.punkIndexToAddress(punkId).call();
  const punkIsOwnedByUser = ownerOfPunk === expectedOwner;

  const wrapperExists = await proxyWrapperPunks.methods.exists(punkId).call();

  const punksOwnerForProxy = wrapperExists ? await proxyWrapperPunks.methods.punksOwnerForProxy(ownerOfPunk).call() : null;

  const ownerOfWrapper = wrapperExists ? await proxyWrapperPunks.methods.ownerOf(punkId).call().catch(doesntExist) : null;
  const isEmptyWrapper = wrapperExists ? await proxyWrapperPunks.methods.isEmptyWrapper(punkId).call().catch(doesntExist) : null;
  const wrapperIsOwnedByUser = ownerOfWrapper === expectedOwner;
  const punkWasWrappedByOwner = punksOwnerForProxy === expectedOwner;
  return {
    ownerOfPunk,
    wrapperExists,
    ownerOfWrapper,
    wrapperIsOwnedByUser,
    punkIsOwnedByUser,
    isEmptyWrapper,
    punksOwnerForProxy,
    punkWasWrappedByOwner,
  };
}

export interface GetPunksDataImageRequest {
  punkId: string;
}

export const getPunksDataImage = async ({ punkId }: GetPunksDataImageRequest): Promise<string> => {
  const cryptoPunksData = await getCryptoPunksDataContract();
  return cryptoPunksData.methods.punkImageSvg(punkId).call();
}

export const getPunksDataImageWithLocalStorageCache = async ({ punkId }: GetPunksDataImageRequest): Promise<string> => {
  let myStorage;
  try {
    myStorage = window.localStorage;
    const key = `PunksData_punkImageSvg_${punkId}`;
    const imageInStorage = myStorage.getItem(key);
    if (imageInStorage) {
      return imageInStorage;
    }
    const image = await getPunksDataImage({ punkId });
    myStorage.setItem(key, image);
    return image;
  } catch (e) {
    return getPunksDataImage({ punkId });
  }
}

export interface CreateProxyResponse {
  contractAddress: string;
}

export const createProxy = async (): Promise<CreateProxyResponse> => {
  const user = await getConnectedAddress();

  const result = await callAndWaitForEvent<CreateProxyResponse>(getPunksWrapperProxyCollectionContract, "createProxy", [], "ProxyCreated", { sender: user });
  return {
    contractAddress: result.contractAddress,    
  }
}

export interface OfferPunkToProxyForWrappingRequest {
  punkIndex: string;
}

export const offerPunkToProxyForWrapping = async ({ punkIndex }: OfferPunkToProxyForWrappingRequest) => {
  const user = await getConnectedAddress();
  const proxyWrapperPunks = await getPunksWrapperProxyCollectionContract();
  const toAddress = await proxyWrapperPunks.methods.proxyForPunksOwner(user).call();
  if (toAddress === ethers.constants.AddressZero) {
    throw new Error("No proxy")
  }

  await callAndWaitForEvent(getCryptoPunksMarketContract, "offerPunkForSaleToAddress", [punkIndex, 0, toAddress], "PunkOffered", { punkIndex, toAddress });
}

export interface WrapRequest {
  tokenId: string;
}

export const wrap = async ({ tokenId }: WrapRequest) => {
  const user = await getConnectedAddress();

  await callAndWaitForEvent(getPunksWrapperProxyCollectionContract, "wrap", [tokenId], "Transfer", { to: user, tokenId });
}

export interface IsPunkOfferedToProxyRequest {
  punkId: string;
  proxyAddress: string;
}

export interface IsPunkOfferedToProxyResponse {
  isPunkOfferedToProxy: boolean;
}

export const isPunkOfferedToProxy = async ({ punkId, proxyAddress }: IsPunkOfferedToProxyRequest): Promise<IsPunkOfferedToProxyResponse> => {
  const punks = await getCryptoPunksMarketContract();

  const { isForSale, onlySellTo, minValue } = await punks.methods.punksOfferedForSale(punkId).call();
  const isPunkOfferedToProxy = isForSale && `${minValue}` === "0" && onlySellTo === proxyAddress;
  return {
    isPunkOfferedToProxy
  };
}

export interface RewrapFullWrapperRequest {
  tokenId: string;
}

export const rewrapFullWrapper = async ({ tokenId }: RewrapFullWrapperRequest) => {
  const user = await getConnectedAddress();
  const proxyWrapperPunks = await getPunksWrapperProxyCollectionContract();
  const proxyAddress = await proxyWrapperPunks.methods.proxyForPunksOwner(user).call();

  await callAndWaitForEvent(getPunksWrapperProxyCollectionContract, "rewrapFullWrapper", [tokenId], "PunkTransfer", { to: proxyAddress, punkIndex: tokenId }, getCryptoPunksMarketContract);
}

export interface RewrapEmptyWrapperRequest {
  tokenId: string;
}

export const rewrapEmptyWrapper = async ({ tokenId }: RewrapEmptyWrapperRequest) => {
  const user = await getConnectedAddress();

  await callAndWaitForEvent(getPunksWrapperProxyCollectionContract, "rewrapEmptyWrapper", [tokenId], "Transfer", { to: user, tokenId });
}

