import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  connectWalletAsync, newWalletConnected,
  SimpleAsyncState, walletDisconnected,
  buildSimpleAsyncCases
} from '@cyberpnk/component-library';
import {
  CheckForSaleRequest,
  CheckForSaleResponse,
  OfferPunkForSaleRequest,
  checkForSale,
  offerPunkForSale,
  UnwrapRequest,
  unwrap,
  DiscardEmptyWrapperRequest,
  discardEmptyWrapper,
  PunkNoLongerForSaleRequest, punkNoLongerForSale
} from './managePunkApi';
import {
  check,
  CheckPunkRequest,
  CheckPunksResponse,
  GetPunksDataImageRequest,
  getPunksDataImageWithLocalStorageCache
} from '../WrapPunkPage/wrapPunkApi';

export interface ManagePunkPageState {
  check: SimpleAsyncState<CheckPunksResponse, CheckPunkRequest>;
  checkForSale: SimpleAsyncState<CheckForSaleResponse, CheckForSaleRequest>;
  getPunksDataImage: SimpleAsyncState<string, GetPunksDataImageRequest>;
  offerPunkForSale: SimpleAsyncState<void, OfferPunkForSaleRequest>;
  unwrap: SimpleAsyncState<void, UnwrapRequest>;
  discardEmptyWrapper: SimpleAsyncState<void, DiscardEmptyWrapperRequest>;
  punkNoLongerForSale: SimpleAsyncState<void, PunkNoLongerForSaleRequest>;
}

const initialState: ManagePunkPageState = {
  check: { loading: false, },
  checkForSale: { loading: false, },
  getPunksDataImage: { loading: false, },
  offerPunkForSale: { loading: false, },
  unwrap: { loading: false, },
  discardEmptyWrapper: { loading: false, },
  punkNoLongerForSale: { loading: false, },
};

export const checkAsync = createAsyncThunk(
  'managePunkPage/check',
  async (request: CheckPunkRequest, thunkAPI) => {
    return await check(request);
  }
);

export const checkForSaleAsync = createAsyncThunk(
  'managePunkPage/checkForSale',
  async (request: CheckForSaleRequest, thunkAPI) => {
    return await checkForSale(request);
  }
);

export const getPunksDataImageAsync = createAsyncThunk(
  'manaagePunkPage/getPunksDataImage',
  async ({ punkId }: GetPunksDataImageRequest, thunkAPI) => {
    return await getPunksDataImageWithLocalStorageCache({ punkId });
  }
);

export const offerPunkForSaleAsync = createAsyncThunk(
  'manaagePunkPage/offerPunkForSale',
  async (request: OfferPunkForSaleRequest, thunkAPI) => {
    return await offerPunkForSale(request);
  }
);

export const punkNoLongerForSaleAsync = createAsyncThunk(
  'manaagePunkPage/punkNoLongerForSale',
  async (request: PunkNoLongerForSaleRequest, thunkAPI) => {
    return await punkNoLongerForSale(request);
  }
);

export const unwrapAsync = createAsyncThunk(
  'manaagePunkPage/unwrap',
  async (request: UnwrapRequest, thunkAPI) => {
    return await unwrap(request);
  }
);

export const discardEmptyWrapperAsync = createAsyncThunk(
  'manaagePunkPage/discardEmptyWrapper',
  async (request: DiscardEmptyWrapperRequest, thunkAPI) => {
    return await discardEmptyWrapper(request);
  }
);


const toInitialState = (state: ManagePunkPageState, initialState: ManagePunkPageState) => {
  state.check = initialState.check;
  state.checkForSale = initialState.checkForSale;
  state.getPunksDataImage = initialState.getPunksDataImage;
  state.unwrap = initialState.unwrap;
  state.offerPunkForSale = initialState.offerPunkForSale;
  state.punkNoLongerForSale = initialState.punkNoLongerForSale;
  state.discardEmptyWrapper = initialState.discardEmptyWrapper;
}

export const managePunkPageSlice = createSlice({
  name: 'managePunkPage',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // this didnt work
    // [checkAsync.pending.type]: (state: WrapPunkPageState, action: { payload: CheckPunksResponse }) => {
    //   state.offerPunkToProxyForWrapping = initialState.offerPunkToProxyForWrapping;
    //   state.isPunkOfferedToProxy = initialState.isPunkOfferedToProxy;
    //   state.wrap = initialState.wrap;
    //   state.createProxy = initialState.createProxy;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    buildSimpleAsyncCases(builder, checkAsync, "check");
    buildSimpleAsyncCases(builder, checkForSaleAsync, "checkForSale");
    buildSimpleAsyncCases(builder, getPunksDataImageAsync, "getPunksDataImage");
    buildSimpleAsyncCases(builder, offerPunkForSaleAsync, "offerPunkForSale");
    buildSimpleAsyncCases(builder, punkNoLongerForSaleAsync, "punkNoLongerForSale");
    buildSimpleAsyncCases(builder, unwrapAsync, "unwrap");
    buildSimpleAsyncCases(builder, discardEmptyWrapperAsync, "discardEmptyWrapper");

    builder
      .addCase(connectWalletAsync.pending, (state) => {
        toInitialState(state, initialState);
      })
      .addCase(newWalletConnected, (state) => {
        toInitialState(state, initialState);
      })
      .addCase(walletDisconnected, (state) => {
        toInitialState(state, initialState);
      })
      .addMatcher(({ type }) => type === checkAsync.pending.type, (state) => {
        state.unwrap = initialState.unwrap;
        state.punkNoLongerForSale = initialState.punkNoLongerForSale;
        state.offerPunkForSale = initialState.offerPunkForSale;
        state.discardEmptyWrapper = initialState.discardEmptyWrapper;
      })
  },
});

// export const {
//   // changeSelectedPfpContract,
// } = managePunkPageSlice.actions;

export const selectManagePunkPageState = (state: any): ManagePunkPageState => state.managePunkPage;

export const selectCheck = (state: any) => selectManagePunkPageState(state).check;
export const selectCheckForSale = (state: any) => selectManagePunkPageState(state).checkForSale;
export const selectGetPunksDataImage = (state: any) => selectManagePunkPageState(state).getPunksDataImage;
export const selectOfferPunkForSale = (state: any) => selectManagePunkPageState(state).offerPunkForSale;
export const selectPunkNoLongerForSale = (state: any) => selectManagePunkPageState(state).punkNoLongerForSale;
export const selectUnwrap = (state: any) => selectManagePunkPageState(state).unwrap;
export const selectDiscardEmptyWrapper = (state: any) => selectManagePunkPageState(state).discardEmptyWrapper;

export const managePunkPageReducer = managePunkPageSlice.reducer;

export default managePunkPageSlice.reducer;
